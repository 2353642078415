<template>
  <div class="buildings-page">
    <Header :type="this.headerTypes.BUILDINGS"/>
    <div class="rf-orange-background pt-5">
      <b-container>
        <b-row>
          <b-col>
            <h3 class="text suptitle">{{$t('Discover our')}}</h3>
            <h1 class="text title">{{$t('RooF Buildings')}}</h1>
            <p class="text">{{$t('A beautiful high-ceilinged mansion in one of the hippest streets of Milan.')}}</p>
          </b-col>
        </b-row>
      </b-container>
      <img class="mt-4 w-100 h-50" src="@/assets/images/white_texture.png">
    </div>
    <b-container>
      <b-row class="my-4">
        <b-col cols="12" md="4" v-for="(image, index) in images" :key="index">
          <div class="buildings-images my-3 my-md-0" :style="{ backgroundImage: `url(${image.url})` }" />
        </b-col>
      </b-row>
    </b-container>
    <div class="building-infos">
      <b-container>
        <b-row class="align-items-center">
          <b-col cols="12" sm="6" md="3" class="building-info-container" v-for="(info, index) in buildingInfo" :key="index" >
            <img class="building-info" src="/images/arrow_orange.png">
            <div class="building-info-label mt-3 mb-3 mb-md-0">{{$t(info.label)}}</div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div ref="buildings">
      <BuildingList :list="buildingList" />
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'
import BuildingList from '@/components/accomodations/buildings/BuildingList'
import { buildingService } from '@/services'
import {headerTypes} from '@/enums'

export default {
  components: { Header, BuildingList },
  name: 'BuildingsPage',
  data() {
    return {
      headerTypes,
      buildingList: [],
      buildingInfo: [
        { label: 'Unlimited WiFi' },
        { label: 'Outdoor spaces' },
        { label: 'Bike parking' },
        { label: 'Doorkeepers' },
      ],
      images: [
        { url: '/images/roof_building_1.jpg' },
        { url: '/images/roof_building_2.jpg' },
        { url: '/images/roof_building_3.jpg' },
      ]
    }
  },
  async beforeMount() {
    this.buildingList = await buildingService.getBuildings()
  }
}
</script>

<style lang="scss" scoped>
.buildings-page {
  .buildings-images {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 500px;
  }
  .text {
    @include font-style($montserrat, 'medium', $font-16);
    color: $white;
    margin: 20px 0 0 0;

    &.suptitle {
      @include font-style($montserrat-bold, 'bold', $font-34);
      margin: 0;
    }
    &.title {
      @include font-style($montserrat-bold, 'bold', $font-44);
      margin: 0;
    }
  }
  .building-infos {
    background-color: $beige;
    padding: 50px 0 30px 0;

    .building-info-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .building-info {
        cursor: pointer;

        &:hover {
          content: url('/images/arrow_orange_hover.png');
          + .building-info-label {
            opacity: 1;
          }
        }
      }
    }
    .building-info-label {
      @include font-style($montserrat, 'medium', $font-16);
      color: $dark-blue;
      opacity: 0;
      transition: opacity .3s;
    }
  }
}
</style>
