<template>

  <div>
    <div class="building" v-bind:class="[ getFormat(building.primaryImgPath)==='mp4' ? 'video-container' : '']" v-bind:style="[getFormat(building.primaryImgPath)!=='mp4' ? { backgroundImage: `url(${getImage(building.primaryImgPath)})`} : {}]">
      <video v-if="getFormat(building.primaryImgPath)==='mp4'" autoplay muted loop playsinline>
        <source :src="getImage(building.primaryImgPath)" type="video/mp4">
      </video>
      <b-container>
        <b-row>
          <b-col cols="12" md="6" lg="4">
            <div class="building-box">
              <div class="building-header">
                <h3 class="building-suptitle">{{$t('Roof Building')}}</h3>
                <h1 class="building-title">{{building.buildingName}}</h1>
              </div>
              <div class="rf-card-waves building-waves"></div>
              <div class="building-type">{{building.resType.name}}</div>
              <div class="building-universities" v-if="building.nearestUni && building.nearestUni.length">
                <div class="nearest-universities mb-2">{{$t('Nearest Universities')}}</div>
                <div class="university" v-for="(uni, index) in building.nearestUni" :key="index">
                  {{uni}}
                </div>
              </div>
              <router-link :to="{ name: 'building-page', params: { name: building.buildingName }}">
                <RFButton class="building-show-me-more"
                          full-width
                          label="Show Me More"
                          background="#fff"
                          color="#E26F5A"
                          size="large"
                          @on-click="selectBuilding(building)"/>
              </router-link>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import RFButton from '@/components/forms/RFButton'
import { getAsset } from '@/helpers'

export default {
  components: { RFButton },
  props: {
    building: {
      type: Object,
      required: true,
    }
  },
  methods:{
    selectBuilding(building) {
      this.$store.commit('buildingStore/setBuilding', building)
    },
    getImage(image) {
      return getAsset(image)
    },
    getFormat(image){
      return image.split('.')[1]
    }
  }
}
</script>

<style lang="scss" scoped>
.video-container {
  position: relative;
}

.video-container video {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  z-index: 0;
}

.video-wrapper {
  /* Will not allow the video to overflow the
  container */
  overflow: hidden;

  /* Centering the container's content vertically
  and horizontally */
  display: flex;
  align-items: center;
  justify-content: center;
}

.building {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 60px 0;

  .building-box {
    background-color: $white;
    padding: 40px 50px;

    .building-header {
      .building-suptitle {
        @include font-style($montserrat-bold, 'bold', $font-24);
        margin: 0;
        color: $dark-blue;
      }
      .building-title {
        @include font-style($montserrat-bold, 'bold', $font-40);
        text-transform: uppercase;
        color: $dark-blue;
      }
    }
    .building-waves {
      background-image: url("/images/orange_roofs.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 210px;
      height: 45px;
      transform: translateX(-40%);
      margin: 25px 0;
    }
    .building-type {
      margin-top: 30px;
      text-transform: uppercase;
      @include font-style($montserrat-bold, 'bold', $font-16);
      color: $dark-blue;
      padding: 0 30px 10px 0;
      border-bottom: 1px solid $beige;
      width: fit-content;
    }
    .building-universities {
      .nearest-universities {
        @include font-style($montserrat, 'semibold', $font-14);
        text-transform: uppercase;
        color: $dark-blue;
        padding-top: 10px;
      }
      .university {
        @include font-style($montserrat-light, 'light', $font-12);
        color: $dark-blue;
      }
    }
    .building-show-me-more {
      margin-top: 60px;
    }
  }
}
</style>
