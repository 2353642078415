<template>
  <div class="building-list">
    <BuildingItem v-for="(item, index) in list" :key="index" :building="item" class="building-item"/>
  </div>
</template>

<script>
import BuildingItem from "@/components/accomodations/buildings/BuildingItem"
import Header from "@/components/Header"

export default {
  props: {
    list: {
      type: Array,
      required: true,
    }
  },
  components: { BuildingItem, Header },
}
</script>

<style lang="scss" scoped>
.building-list {
  .building-item {
    margin-bottom: 70px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
